body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    color: lightgray;
    background-color: #000000;
    overflow: hidden;
}

@media (prefers-color-scheme: light) {
    body {
      color: #333;
      background-color: #ffffff;
    }
}

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px); 
  text-align: center;
}

.search-icon {
    font-size: 50px;
}

.not-found-text {
    font-weight: bold;
    font-size: 28px;
    margin: 20px 0;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #222;
  padding: 20px;
  text-align: center;
}

.footer a {
    color: lightgray;
    text-decoration: none;
}

  .footer-info {
    display: flex;
    align-items: center; 
    justify-content: center;
    font-size: 18px;
    padding-bottom: 10px;
  }
  
  .footer-icon {
    height: 1.5em;
    vertical-align: middle; 
    width: auto;
  }
  
  .footer-name {
    font-weight: bold;
    font-size: 25px;
    margin-left: 5px; 
  }