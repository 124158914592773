.container {
    background: black;
    color: white;
    font-family: Arial, sans-serif;
    padding: 20px; /* Stellt sicher, dass der Abstand um den Inhalt gleichmäßig ist */
    border-radius: 30px;
    position: fixed;
    bottom: 35px;
    left: 10px;
    right: 10px; /* Stellt sicher, dass auf beiden Seiten ein Abstand vorhanden ist */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  .top-section {
    align-self: stretch; /* Streckt die top-section über die gesamte Breite des Containers */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Sorgt dafür, dass der Text linksbündig ist */
    padding-right: 10px; /* Fügt rechts etwas Platz hinzu, falls benötigt */
    padding-left: 10px;
    padding-bottom: 10px;
  }
  
  .runtime-label {
    font-size: 16px;
    font-weight: 800;
    color: #ffffff8d;
  }
  
  .runtime {
    font-size: 28px;
    font-weight: 800;
    color: white;
    margin-bottom: 10px;
  }
  
  .stats-box {
    background: #1C1C1E;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    border-radius: 20px;
    width: calc(100% - 20px);
  }
  
  .stat {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Ändere von center zu flex-start für Links Ausrichtung */
    flex: 1; /* Gibt jedem Stat-Element die gleiche Flexibilität */
    position: relative;
    padding: 0 20px;
  }
  
  .stat:not(:last-child):after {
    content: "";
    position: absolute;
    right: 0;
    top: 10%;
    bottom: 10%;
    width: 1px;
    background-color: #ffffff7a; /* Dunkler als der Hintergrund */
  }
  
  .label-distance {
    color: #5873A3;
    font-size: 21px;
    font-weight: 700;
  }
  
  .label-pace {
    color: #A36258;
    font-size: 21px;
    font-weight: 700;
  }
  
  .label-altitude {
    color: #A38B58;
    font-size: 21px;
    font-weight: 700;
  }
  
  .value {
    color: #ffffff;
    font-size: 24px;
    font-weight: 800;
  }
  
  /* Anpassungen für große Bildschirme */
  @media (min-width: 601px) {
    .container {
      left: 10px; /* Links unten positionieren */
      right: auto; /* Standardrechte-Positionierung aufheben */
      width: 400px; /* Breite für große Bildschirme festlegen */
    }
  }
  
  /* Anpassungen für kleinere Bildschirme */
  @media (max-width: 600px) {
    .container {
      left: 50%; /* Zentrierung für kleine Bildschirme */
      transform: translateX(-50%); /* Zentrierung korrigieren */
      width: calc(100% - 60px); /* Abstand von beiden Seiten */
    }

    .impressum-link {
      bottom: auto; /* Aufheben der unteren Positionierung */
      top: -35px; /* Verschiebt den Link nach oben außerhalb des Containers */
      left: 0; /* Positioniert den Link auf der linken Seite */
      right: auto; /* Aufheben der rechten Positionierung */
      transform: none; /* Entfernt vorherige Transformationen */
    }
  }

  .impressum-link {
    position: absolute;
    bottom: -35px; /* Verschiebt den Link nach unten außerhalb des Containers */
    right: 0; /* Positioniert den Link auf der rechten Seite */
    color: white;
    text-decoration: underline;
    padding: 10px;
  }